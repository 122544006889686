export class BrowserUtil {

  private static supports: boolean = undefined;

  public static supportsLocalStorage(): boolean {
    if (BrowserUtil.supports === undefined) {
      return BrowserUtil.checkSupportsLocalStorage();
    } else {
      return BrowserUtil.supports;
    }
  }

  /**
   * Returns undefined if Browser does not support localStorage.
   *
   * @param item
   */
  public static getLocalStorageItem(item: string): string {
    if (BrowserUtil.supportsLocalStorage()) {
      return window.localStorage.getItem(item);
    }
  }

  /**
   * Sets the item to the localStorage if the Browser supports it.
   *
   * @param item
   * @param value
   */
  public static setLocalStorageItem(item: string, value: string): void {
    if (BrowserUtil.supportsLocalStorage()) {
      window.localStorage.setItem(item, value);
    }
  }

  /**
   * Removes the item from the localStorage if the Browser supports it.
   *
   * @param item
   */
  public static removeLocalStorageItem(item: string): void {
    if (BrowserUtil.supportsLocalStorage()) {
      window.localStorage.removeItem(item);
    }
  }

  private static checkSupportsLocalStorage(): boolean {
    const dummyEntry = 'dummyEntry';
    try {
      window.localStorage.setItem(dummyEntry, dummyEntry);
      const item = window.localStorage.getItem(dummyEntry);
      if (item && item === dummyEntry) {
        window.localStorage.removeItem(dummyEntry);
        BrowserUtil.supports = true;
      } else {
        BrowserUtil.supports = false;
      }
    } catch (e) {
      BrowserUtil.supports = false;
    }
    return BrowserUtil.supports;
  }

}
