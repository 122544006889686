import {
  BaseSettings,
  Clef,
  Instrument,
  LanguageSettings,
  SaveSettings,
  ScaleSettings,
} from '../components/views/view.types';
import { NoteName, ScaleType } from '../core/types';
import { LanguageKey } from '../services/translate.service';
import { BrowserUtil } from './browser-util';

export enum LocalStorageKey {
  saveSettings = 'saveSettings',
  scaleSettings = 'scaleSettings',
  baseSettings = 'baseSettings',
  languageSettings = 'languageSettings'
}

export class SettingsUtil {

  static loadSaveSettings(): SaveSettings {
    let parsedSettings: SaveSettings;
    try {
      parsedSettings = JSON.parse(BrowserUtil.getLocalStorageItem(LocalStorageKey.saveSettings));
    } catch (e) {
    }

    return this.sanitizeSaveSettings(parsedSettings);
  }

  static loadScaleSettings(): ScaleSettings {
    let parsedSettings: ScaleSettings;
    try {
      parsedSettings = JSON.parse(BrowserUtil.getLocalStorageItem(LocalStorageKey.scaleSettings));
    } catch (e) {
    }

    if (!parsedSettings) {
      return null;
    }
    return this.sanitizeScaleSettings(parsedSettings);
  }

  static loadBaseSettings(): BaseSettings {
    let parsedSettings: BaseSettings;
    try {
      parsedSettings = JSON.parse(BrowserUtil.getLocalStorageItem(LocalStorageKey.baseSettings));
    } catch (e) {
    }

    if (!parsedSettings) {
      return null;
    }
    return this.sanitizeBaseSettings(parsedSettings);
  }

  static loadLanguageSettings(): LanguageSettings {
    let parsedSettings: LanguageSettings;
    try {
      parsedSettings = JSON.parse(BrowserUtil.getLocalStorageItem(LocalStorageKey.languageSettings));
    } catch (e) {
    }

    if (!parsedSettings) {
      return {language: LanguageKey.de};
    }
    return this.sanitizeLanguageSettings(parsedSettings);
  }

  static saveScaleSettings(settings: ScaleSettings): void {
    this.saveSetting(LocalStorageKey.scaleSettings, this.sanitizeScaleSettings(settings));
  }

  static saveBaseSettings(settings: BaseSettings): void {
    this.saveSetting(LocalStorageKey.baseSettings, this.sanitizeBaseSettings(settings));
  }

  static saveSaveSettings(settings: SaveSettings) {
    const saveSettings = this.sanitizeSaveSettings(settings);
    if (saveSettings?.autoSave) {
      this.saveSetting(LocalStorageKey.saveSettings, saveSettings);
    }
  }

  static saveLanguageSettings(settings: LanguageSettings): void {
    this.saveSetting(LocalStorageKey.languageSettings, this.sanitizeLanguageSettings(settings));
  }

  static removeSettings(): void {
    Object.values(LocalStorageKey).forEach(key => BrowserUtil.removeLocalStorageItem(key));
  }

  private static saveSetting(key: LocalStorageKey, value: any): void {
    BrowserUtil.setLocalStorageItem(key, JSON.stringify(value));
  }

  private static sanitizeSaveSettings(parsedSettings: SaveSettings): SaveSettings {
    return {
      autoSave: !!parsedSettings?.autoSave
    } as SaveSettings;
  }

  private static sanitizeLanguageSettings(parsedSettings: LanguageSettings): LanguageSettings {
    let languageSettings: LanguageSettings = {};
    if (Object.values(LanguageKey).includes(parsedSettings.language)) {
      languageSettings.language = parsedSettings.language;
    }

    if (Object.keys(languageSettings).length === 0) {
      languageSettings = {language: LanguageKey.de};
    }
    return languageSettings;
  }

  private static sanitizeScaleSettings(parsedSettings: ScaleSettings): ScaleSettings {
    let scaleSettings: ScaleSettings = {};
    if (Object.values(NoteName).includes(parsedSettings.keyNoteName)) {
      scaleSettings.keyNoteName = parsedSettings.keyNoteName;
    }
    if (Object.values(ScaleType).includes(parsedSettings.scaleType)) {
      scaleSettings.scaleType = parsedSettings.scaleType;
    }

    if (Object.keys(scaleSettings).length === 0) {
      scaleSettings = null;
    }
    return scaleSettings;
  }

  private static sanitizeBaseSettings(parsedSettings: BaseSettings): BaseSettings {
    let baseSettings: BaseSettings = {};
    if (Object.values(Instrument).includes(parsedSettings.instrument)) {
      baseSettings.instrument = parsedSettings.instrument;
    }
    if ([...Object.values(Clef), 'auto'].includes(parsedSettings.clefMode)) {
      baseSettings.clefMode = parsedSettings.clefMode;
    }
    if (typeof parsedSettings.showFingering === 'boolean') {
      baseSettings.showFingering = parsedSettings.showFingering;
    }
    if (typeof parsedSettings.showNoteSymbol === 'boolean') {
      baseSettings.showNoteSymbol = parsedSettings.showNoteSymbol;
    }
    if (typeof parsedSettings.showSolmisationName === 'boolean') {
      baseSettings.showSolmisationName = parsedSettings.showSolmisationName;
    }

    if (Object.keys(baseSettings).length === 0) {
      baseSettings = null;
    }
    return baseSettings;
  }

}
